<template>
    <div class="index-container main-wrapper">
        <div class="index-tabs add-subject-option">
            <el-form :model="subjectForm" :rules="rules" ref="subjectForm" label-width="100px" class="subject-ruleForm">
                <el-scrollbar class="subject-content" :native="false">
                    <el-form-item label="题型" prop="type">
                        <el-radio-group v-model="subjectForm.type" size="small">
                            <el-radio-button :label=1 disabled>单选题</el-radio-button>
                            <el-radio-button :label=2 disabled>多选题</el-radio-button>
                            <el-radio-button :label=3 disabled>判断题</el-radio-button>
                            <el-radio-button :label=4 disabled>填空题</el-radio-button>
                            <el-radio-button :label=5 disabled>问答题</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="难度" prop="type">
                        <el-radio-group v-model="subjectForm.deep" size="small">
                            <el-radio-button label=1>容易</el-radio-button>
                            <el-radio-button label=2>较易</el-radio-button>
                            <el-radio-button label=3>普通</el-radio-button>
                            <el-radio-button label=4>较难</el-radio-button>
                            <el-radio-button label=5>困难</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="分类" prop="specialized">
                        <el-cascader v-model="subjectForm.specialized" style="width: 300px" size="medium" @change="filterTagsList" :props="specializedKeyObj" :options="specializedList" clearable placeholder="请选择分类"></el-cascader>
                    </el-form-item>
                    <el-form-item label="题目" prop="title" :class="subjectForm.type === 4 ? 'subject-fill' : 'subject-quill'">
                        <quill-editor v-model="subjectForm.title"
                                      ref="titleQuillEditor"
                                      :options="titleEditorOption"
                                      @blur="onEditorBlurTitle($event)"
                                      @focus="onEditorFocus($event)"
                                      @ready="onEditorReady($event)"
                                      @change="onEditorChange($event)">
                        </quill-editor>
                        <span style="color: red;">*注:(1)标题不用写序号，如：1.  2.  3. </span>
                        <span style="color: red;" v-if="subjectForm.type === 4">，(2)[英文状态]的下划线为填空部分，4个下划线为一个空，连续两个空用逗号或空格隔开。</span>
                    </el-form-item>
                    <el-form-item label="答案选项" prop="answer" :class="subjectForm.type === 5 ? 'subject-short-answer' : ''">
                        <SingleChoice :data="subjectForm.options" v-if="subjectForm.type === 1"></SingleChoice>
                        <MultipleChoice :data="subjectForm.options" v-if="subjectForm.type === 2"></MultipleChoice>
                        <Judge :data="subjectForm.options" v-if="subjectForm.type === 3"></Judge>
                        <Fill :data="subjectForm.options" v-if="subjectForm.type === 4"></Fill>
                    </el-form-item>
                    <el-form-item label="题目解析" prop="analyse" class="subject-quill">
                        <quill-editor v-model="subjectForm.analyse"
                                      ref="answerQuillEditor"
                                      :options="answerEditorOption"
                                      @blur="onEditorBlurAnalyse($event)"
                                      @focus="onEditorFocus($event)"
                                      @ready="onEditorReady($event)">
                        </quill-editor>
                        <span style="color: red;" v-if="subjectForm.type === 5">*注：问答题的题目解析必须填写！</span>
                    </el-form-item>
                    <el-form-item label="标签">
                        <el-cascader style="width: 200px" :props="tagsKeyObj" :options="tagsList" ref="tagsObj" @change="changeTags" placeholder="请选择标签"></el-cascader>
                        <el-tag closable v-for="tag in theTags" @close="delTags(tag)" class="the_tags" style="margin: 0 5px;" v-if="tag">{{tag.name}}</el-tag>
                    </el-form-item>
                </el-scrollbar>
                <el-form-item>
                    <el-button type="primary" @click="subjectSubmitForm('subjectForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import _ from "underscore";
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import { quillEditor } from 'vue-quill-editor'

    import SingleChoice from 'components/material/subjectType/SingleChoice'
    import MultipleChoice from 'components/material/subjectType/MultipleChoice'
    import Judge from 'components/material/subjectType/Judge'
    import Fill from 'components/material/subjectType/Fill'

    export default {
        name: "SubjectEdit",
        created() {
            //获取试题信息
            this.$http.axiosGetBy(this.$api.updTopic, {id: this.$route.query.id}, (res) => {
                if (res.code === 200) {
                    this.subjectForm.type = res.data.topic_type;
                    this.subjectForm.deep = res.data.topic_difficulty;
                    this.subjectForm.specialized = res.data.rc_ids;
                    this.getTagsList();
                    for (let i in res.data.tag_name) {
                        let tag = {}
                        tag.id = parseInt(i);
                        tag.name = res.data.tag_name[i];
                        this.theTags.push(tag)
                    }
                    this.subjectForm.title = JSON.parse(res.data.topic).title;
                    this.subjectForm.analyse = JSON.parse(res.data.topic).analyse;
                    this.subjectForm.options = JSON.parse(res.data.topic).options;
                }
            }, (err) => {
                console.log(err)
            })
            //获取分类列表
            this.getSpecializedList();
        },
        data () {
            return {
                titleEditorOption: {
                    placeholder: "请输入题目..."
                },
                answerEditorOption: {
                    placeholder: "请输入题目解析，非必填..."
                },
                //试题data
                subjectForm: {
                    type: 1,
                    deep: 1,
                    specialized: [],
                    title: '',
                    options: {
                        option: [],
                        value: []
                    },
                    analyse: '',
                    tags: []
                },
                rules: {
                    type: [
                        { required: true, message: '请选择题型', trigger: 'change' }
                    ],
                    deep: [
                        { required: true, message: '请选择难度', trigger: 'change' }
                    ],
                    specialized: [
                        { required: true, message: '请选择分类', trigger: 'change' }
                    ],
                    // title: [
                    //     { required: true, message: '请输入题目', trigger: 'blur' },
                    //     { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
                    // ],
                    tags: [
                        { required: true, message: '请选择标签', trigger: 'change' }
                    ]
                },
                //选择专业分类
                specializedList: [],
                specializedKeyObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children'
                },
                //选择标签组列表
                tagsList: [],
                tagsKeyObj: {
                    label: 'name',
                    value: 'id'
                },
                //标签
                theTags: []
            }
        },
        components: {
            quillEditor,
            SingleChoice,
            MultipleChoice,
            Judge,
            Fill
        },
        methods: {
            onEditorBlurTitle(quill) {},
            onEditorBlurAnalyse (quill) {},
            onEditorFocus(quill) {},
            onEditorReady(quill) {},
            onEditorChange({ quill, html, text }) {
                // console.log('editor change!',  text)
                // this.content = html
                // if(!text.replace(/^\s+|\s+$/g,"")) return;
                // this.subjectForm.title = text;
            },
            //专业分类
            getSpecializedList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 1}, (res) => {
                    if (res.code === 200) {
                        this.specializedList = this.getSpecializedListData(res.list);
                    }
                })
            },
            getSpecializedListData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getSpecializedListData(data[i].children)
                    }
                }
                return data;
            },
            filterTagsList() {
                this.getTagsList();
            },
            //标签列表
            getTagsList() {
                let params = {
                    rc_id: this.subjectForm.specialized[this.subjectForm.specialized.length - 1]
                }
                this.$http.axiosGetBy(this.$api.show_tag, params,(res) => {
                    if (res.code === 200) {
                        this.tagsList = res.data;
                    }
                })
            },
            //标签选择
            changeTags(value) {
                let obj = _.findWhere(this.tagsList, {id : value[0]});
                let objs = _.findWhere(this.theTags, {id : obj.id});
                if (objs) {
                    this.$message.warning('此标签已存在');
                } else {
                    this.theTags.push(obj);
                }
            },
            //删除标签
            delTags(tag) {
                this.theTags.splice(this.theTags.indexOf(tag), 1);
            },
            //保存
            subjectSubmitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //选项
                        let answer_option = this.subjectForm.options.option;
                        // 判断题目是否为空
                        if(!this.subjectForm.title){
                            this.$message('请先输入题目'); return
                        }
                        // 判断题目字数
                        if(this.subjectForm.title.length < 3) {
                            this.$message('题目不能少于3个字符');
                            return;
                        }
                        //填空题
                        if (this.subjectForm.type === 4) {
                            //匹配下划线
                            let titleMatch = this.subjectForm.title.match(/\_+/g);
                            console.log(titleMatch,'下划线')
                            console.log(answer_option.length,'选项的个数')
                            if(titleMatch !== null) {
                                if(titleMatch.length !== answer_option.length) {
                                    this.$message('设置的空和答案不一致','提示');return
                                }
                            } else {
                                this.$message('尚未设置空处','提示');return
                            }
                        }
                        //判断是否有设置选项
                        if (this.subjectForm.type < 5) {
                            if (answer_option.length === 0) {
                                this.$message('还未设置选项'); return
                            }
                        }
                        //判断是否有设置选项及正确答案
                        for (let i = 0; i < answer_option.length; i++) {
                            let element = answer_option[i];
                            console.log(element,'答案')
                            if(!element.name){
                                this.$message('答案不能为空','提示');return
                            }

                            let correct_answer = this.subjectForm.options.value;
                            if (this.subjectForm.type < 4) {
                                if(correct_answer.length === 0) {
                                    console.log(correct_answer,'设置正确答案')
                                    this.$message('还未设置正确答案'); return
                                }
                            }
                        }
                        //判断是否有设置问答题的题目解析
                        let askAnswer_answer = this.subjectForm.analyse;
                        if (this.subjectForm.type === 5) {
                            if(askAnswer_answer === '') {
                                this.$message('问答题的题目解析必须填！'); return
                            }
                        }

                        let formData = new FormData();
                        formData.append('id', this.$route.query.id);
                        formData.append('topic_type', this.subjectForm.type);
                        formData.append('topic_difficulty', this.subjectForm.deep);
                        formData.append('rc_id', this.subjectForm.specialized[this.subjectForm.specialized.length - 1]);

                        let answer = {};
                        answer.title = this.subjectForm.title;
                        answer.analyse = this.subjectForm.analyse;
                        answer.options = this.subjectForm.options;
                        formData.append('topic', JSON.stringify(answer));
                        if (this.theTags.length === 0) {
                            this.$message({
                                type: 'warning',
                                message: '请选择标签！'
                            });
                            return false;
                        } else {
                            let tag_tmp = [];
                            _.map(this.theTags, function (item) {
                                tag_tmp.push(item.id);
                            });
                            formData.append('topic_tag', tag_tmp.join(','));
                        }

                        this.$http.axiosPost(this.$api.updSaveTopic, formData, (res) => {
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg
                                });
                                this.$router.push('/admin/index');
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
        computed: {
            titleEditor() {
                return this.$refs.titleQuillEditor.quill
            },
            answerEditor() {
                return this.$refs.analyseQuillEditor.quill
            }
        },
        mounted() {
            // console.log('this is current quill instance object', this.titleEditor)
        }
    }
</script>

<style scoped lang="scss">
    .add-subject-option {
        height: 100%;
        padding-top: 10px;
        .subject-ruleForm {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            ::v-deep .el-form-item__label {
                text-align: center;
            }
            .subject-content {
                flex: 1;
                height: calc(100% - 63px);
                padding-right: 15px;
                .subject-quill {
                    height: 200px;
                    margin-bottom: 42px;
                    ::v-deep .el-form-item__content {
                        height: 200px;
                        .quill-editor {
                            height: 200px;
                            .ql-container {
                                height: 100px;
                            }
                        }
                    }
                }
                .subject-fill {
                    height: 200px;
                    margin-bottom: 42px;
                    ::v-deep .el-form-item__content {
                        height: 200px;
                        .quill-editor {
                            height: 200px;
                            .ql-container {
                                height: 100px;
                            }
                        }
                    }
                }
                .subject-short-answer {
                    display: none;
                }
            }
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
     }
</style>